<template>
  <div class="common-wrap">
    <headNav active="1"></headNav>
    <showHeader :showHeaderIndex="4" @showHeaderChange="showHeaderChange" />
    <div class="common-contents">
      <img class="contents-img" src="../assets/ourope/ourope-1.png" alt="" />
      <div class="contents pb-160">
        <img class="contents-img mt-72" src="../assets/ourope/ourope-2.png" alt="" />
        <img class="contents-img mt-72" src="../assets/ourope/ourope-3.png" alt="" />
        <img class="contents-img mt-72" src="../assets/ourope/ourope-4.png" alt="" />
        <img class="contents-img mt-72" src="../assets/ourope/ourope-5.png" alt="" />
        <img class="contents-img mt-72" src="../assets/ourope/ourope-6.png" alt="" />
        <div class="video-wrap">
          <video
            class="video"
            controls
            poster="../assets/ourope/ourope-7.png"
            src="https://suco.oss-cn-hangzhou.aliyuncs.com/vod/web/000004.mp4"
          >
            your browser does not support the video tag
          </video>
        </div>
      </div>
    </div>
    <footNav />
  </div>
</template>

<script>
import headNav from "../components/header2.vue";
import footNav from "../components/footer2.vue";
import showHeader from "../components/showHeader.vue";
export default {
  components: { headNav, footNav, showHeader },
  methods: {
    handlePrev() {
      this.$refs.carousel.prev();
    },
    handleNext() {
      this.$refs.carousel.next();
    },
    // tabs 切换
    showHeaderChange(index) {
      if (index != 4) {
        if (index == 3) {
          this.$router.push({ name: "usshow" });
        } else {
          this.$router.push({ name: "othershow", query: { type: index } });
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.common-contents {
  background-color: #fff;
  width: 100%;
 
  .contents {
    width: 100%;
    position: relative;
    .video-wrap {
      width: 1200px;
      margin: 0 auto;
      height: 577px;
      margin-top: 106px;
      .video {
        width: 100%;
        height: 100%;
        object-fit: fill;
      }
    }
  }
}
 .contents-img{
        width: 100%;
    }
</style>
<style lang="scss">

</style>