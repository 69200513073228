<template>
  <div class="common-wrap">
    <headNav active="7"></headNav>
    <div class="common-contents">
      <div class="banner">
        <img
          src="@/assets/version2/artTeamUnion-1.png"
          alt=""
          srcset=""
          class="img"
          v-if="$i18n.locale == 'zh'"
        />
        <img src="" alt="" srcset="" class="img" v-else />
      </div>
      <div style="background: #f2f6f7">
        <div class="contents">
          <div class="pt-106 flex between">
            <div class="f-36 f-bold t-l" style="color: #444444; width: 431px">
              <div>苏州市民族管弦乐</div>
              <div>艺术团队联合会</div>
            </div>
            <div style="color: #5b5b5b; width: 771px" class="f-16 t-l">
              <p>
                苏州市民族管弦乐艺术团队联合会是由苏州民族管弦乐团等单位发起、苏州市文化和民政部门批准，为苏州民族器乐艺术作曲、理论、指挥、教育、演奏等人才的培养平台和非职业民族器乐爱好者及民乐社团的传播交流平台，由中国广播民族乐团艺术总监兼首席指挥、苏州民族管弦乐团艺术总监兼首席指挥彭家鹏担任会长。
              </p>
              <p class="mt-30">
                联合会的成立，对于整合江南丝竹在新时代的品牌效应，进一步构建完善我市公共文化服务体系，满足人民群众在新时代的精神文化需求，具有重要的意义。
              </p>
              <p class="mt-30">
                会长彭家鹏表示，联合会的成立体现了苏州对新时代民族音乐文化事业再出发的殷切期望。自己将与理事会班子成员一起，用创新机制、用满腔热情、用不懈奋斗，共同把联合会建设好、发展好，打造新时代中国苏州民族音乐文化品牌。
              </p>
            </div>
          </div>
          <div class="mt-19 t-r">
            <img
              src="@/assets/version2/artTeamUnion-2.png"
              alt=""
              srcset=""
              style="width: 1000px; height: 480px"
            />
          </div>
          <div class="mt-106 flex between f-26">
            <div
              @click="change(1)"
              class="t-c content-item cur"
              :class="active == 1 ? 'content-item-acitve' : ''"
            >
              苏州民族音乐展演
            </div>
            <div
              @click="change(2)"
              class="t-c content-item cur"
              :class="active == 2 ? 'content-item-acitve' : ''"
            >
              大运河文化传承展示周
            </div>
            <div
              @click="change(3)"
              class="t-c content-item cur"
              :class="active == 3 ? 'content-item-acitve' : ''"
            >
              苏州市新年音乐会
            </div>
          </div>
        </div>
        <div class="pt-81" style="background: #f3f3f3" v-if="active == 1">
          <div class="contents t-l">
            <div class="c-1 f-26 f-w-5">相关资讯</div>
            <div class="flex between">
              <div class="t-l" style="width: 559px">
                <div
                  class="mt-40 mb-40"
                  style="width: 560px; height: 5px; background: #5a5a5a"
                ></div>
                <img
                  src="@/assets/version2/artTeamUnion-3.png"
                  alt=""
                  srcset=""
                  style="width: 559px; height: 326px"
                />
                <div class="mt-16 f-20 f-w-5">
                  首届江苏·苏州民族音乐展演在苏州民族管弦乐团音乐厅正式开幕
                </div>
                <div class="mt-8 f-18" style="font-family: DIN, DIN-Regular">
                  2022.09.20
                </div>
              </div>
              <div style="width: 500px" class="pt-35">
                <div class="flex f-16 new-item mb-29">
                  <div style="font-family: DIN, DIN-Regular">
                    <div>2022</div>
                    <div>09.20</div>
                  </div>
                  <div style="color: #835c46" class="ml-24">
                    首届江苏·苏州民族音乐展演在苏州民族管弦乐团音乐厅正式开幕
                  </div>
                </div>
                <div class="flex f-16 new-item mb-29">
                  <div style="font-family: DIN, DIN-Regular">
                    <div>2022</div>
                    <div>07.16</div>
                  </div>
                  <div style="color: #666666" class="ml-24">
                    第二届江苏•苏州民族音乐展演举办
                    28家民族音乐艺术团队共襄民族音乐盛会
                  </div>
                </div>
                <div class="flex f-16 new-item mb-29">
                  <div style="font-family: DIN, DIN-Regular">
                    <div>2021</div>
                    <div>12.28</div>
                  </div>
                  <div style="color: #666666" class="ml-24">
                    首届江苏•苏州民族音乐展演圆满闭幕
                  </div>
                </div>
                <div class="flex f-16 new-item mb-29">
                  <div style="font-family: DIN, DIN-Regular">
                    <div>2021</div>
                    <div>12.28</div>
                  </div>
                  <div class="ml-24" style="color: #666666">
                    首届江苏•苏州民族音乐展演启幕 江南丝竹音乐文化再添新品牌
                  </div>
                </div>
                <div class="flex f-16 new-item mb-29">
                  <div style="font-family: DIN, DIN-Regular">
                    <div>2021</div>
                    <div>12.27</div>
                  </div>
                  <div class="ml-24" style="color: #666666">
                    促进苏州优秀民乐团队相互学习交流展示，向着更高的艺术境界迈进
                  </div>
                </div>
              </div>
            </div>
            <!-- 手风琴 -->
            <div class="shell-wrap mt-93">
              <div class="shell">
                <div class="box">
                  <img src="@/assets/version2/artTeamUnion-4.png" alt="" />
                  <span>展演风采</span>
                </div>
                <div class="box">
                  <img src="@/assets/version2/artTeamUnion-5.png" alt="" />
                  <span>展演风采</span>
                </div>
                <div class="box">
                  <img src="@/assets/version2/artTeamUnion-6.png" alt="" />
                  <span>展演风采</span>
                </div>
                <div class="box">
                  <img src="@/assets/version2/artTeamUnion-7.png" alt="" />
                  <span>展演风采</span>
                </div>
                <div class="box">
                  <img src="@/assets/version2/artTeamUnion-8.png" alt="" />
                  <span>展演风采</span>
                </div>
              </div>
            </div>
            <!-- 回顾 -->
            <div class="mt-90 pb-99">
              <div class="show-item">
                <img
                  src="@/assets/version2/artTeamUnion-9.png"
                  alt=""
                  srcset=""
                  class="img-default"
                />
                <div class="show-item-text">
                  <div>第一届</div>
                  <div class="mt-5">苏州民族音乐展演回顾</div>
                </div>
              </div>
              <div class="show-item mt-40">
                <img
                  src="@/assets/version2/artTeamUnion-10.png"
                  alt=""
                  srcset=""
                  class="img-default"
                />
                <div class="show-item-text">
                  <div>第二届</div>
                  <div class="mt-5">苏州民族音乐展演回顾</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 大运河 -->
        <div class="pt-81" style="background: #f3f3f3" v-if="active == 2">
          <div class="contents t-l">
            <div class="c-1 f-26 f-w-5">相关资讯</div>
            <div class="flex between">
              <div class="t-l" style="width: 559px">
                <div
                  class="mt-40 mb-40"
                  style="width: 560px; height: 5px; background: #5a5a5a"
                ></div>
                <img
                  src="@/assets/version2/artTeamUnion-15.png"
                  alt=""
                  srcset=""
                  style="width: 559px; height: 326px"
                />
                <div class="mt-16 f-20 f-w-5">
                  首届运河情 江南韵 大运河民族音乐文化传承展示周正式开幕
                </div>
                <div class="mt-8 f-18" style="font-family: DIN, DIN-Regular">
                  2022.10.15
                </div>
              </div>
              <div style="width: 500px" class="pt-35">
                <div class="flex f-16 new-item mb-29">
                  <div style="font-family: DIN, DIN-Regular">
                    <div>2022</div>
                    <div>10.15</div>
                  </div>
                  <div style="color: #835c46" class="ml-24">
                    运河情 江南韵 大运河民族音乐文化传承展示周正式开幕
                  </div>
                </div>
                <div class="flex f-16 new-item mb-29">
                  <div style="font-family: DIN, DIN-Regular">
                    <div>2022</div>
                    <div>10.09</div>
                  </div>
                  <div style="color: #666666" class="ml-24">
                    60万人次“云”飨盛宴，第二届“运河情·江南韵”——大运河民族音乐文化传承展示周圆满落幕
                  </div>
                </div>
                <div class="flex f-16 new-item mb-29">
                  <div style="font-family: DIN, DIN-Regular">
                    <div>2021</div>
                    <div>09.30</div>
                  </div>
                  <div style="color: #666666" class="ml-24">
                    “民星”与百姓共度长假，第二届“运河情·江南韵”大运河民族音乐文化传承展示周今日开幕
                  </div>
                </div>
                <div class="flex f-16 new-item mb-29">
                  <div style="font-family: DIN, DIN-Regular">
                    <div>2021</div>
                    <div>09.28</div>
                  </div>
                  <div class="ml-24" style="color: #666666">
                    期待！第二届“运河情·江南韵”——大运河民族音乐文化传承展示周即将开幕
                  </div>
                </div>
                <div class="flex f-16 new-item mb-29">
                  <div style="font-family: DIN, DIN-Regular">
                    <div>2021</div>
                    <div>08.22</div>
                  </div>
                  <div class="ml-24" style="color: #666666">
                    “运河情·江南韵”大运河民族音乐文化传承展示周即将在苏举办
                  </div>
                </div>
              </div>
            </div>
            <!-- 手风琴 -->
            <div class="shell-wrap mt-93">
              <div class="shell">
                <div class="box">
                  <img src="@/assets/version2/artTeamUnion-16.png" alt="" />
                  <span>展示周风采</span>
                </div>
                <div class="box">
                  <img src="@/assets/version2/artTeamUnion-17.png" alt="" />
                  <span>展示周风采</span>
                </div>
                <div class="box">
                  <img src="@/assets/version2/artTeamUnion-18.png" alt="" />
                  <span>展示周风采</span>
                </div>
                <div class="box">
                  <img src="@/assets/version2/artTeamUnion-19.png" alt="" />
                  <span>展示周风采</span>
                </div>
                <div class="box">
                  <img src="@/assets/version2/artTeamUnion-20.png" alt="" />
                  <span>展示周风采</span>
                </div>
              </div>
            </div>
            <!-- 回顾 -->
            <div class="mt-90 pb-99">
              <div class="show-item">
                <img
                  src="@/assets/version2/artTeamUnion-21.png"
                  alt=""
                  srcset=""
                  class="img-default"
                />
                <div class="show-item-text">
                  <div>运河情 江南韵</div>
                  <div class="mt-5">大运河民族音乐文化传承展示周</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 新年音乐会 -->
        <div
          class="pt-81 concert-wrap"
          style="background: #f3f3f3"
          v-if="active == 3"
        >
          <div class="contents">
            <div class="title">
              苏州民族管弦乐团新年音乐会《丝竹里的江南》奏响苏州湾大剧院
            </div>
            <img src="@/assets/version2/artTeamUnion-22.png" alt="" srcset="" class="wd mt-72">
            <img src="@/assets/version2/artTeamUnion-23.png" alt="" srcset="" class="wd mt-42">
            <img src="@/assets/version2/artTeamUnion-24.png" alt="" srcset="" class="wd">
            <img src="@/assets/version2/artTeamUnion-25.png" alt="" srcset="" class="wd mt-44">
            <img src="@/assets/version2/artTeamUnion-26.png" alt="" srcset="" class="wd mt-44 pb-55">
          </div>
        </div>
        <!-- 联合会动态 -->
        <div class="pt-106 contents">
          <div class="flex between">
            <div class="c-1 f-26 f-w-5 inbl-m l-2">联合会动态</div>
            <div class="c-2 f-18 f-w-4 inbl-m">更多>></div>
          </div>
          <div class="mt-30 dynamic-wrap flex between">
            <div class="dynamic-item">
              <div class="dynamic-item-img">
                <img
                  src="@/assets/version2/artTeamUnion-11.png"
                  alt=""
                  srcset=""
                  class="img-default"
                />
              </div>
              <div class="dynamic-item-text t-l">
                <div class="text-1">
                  全国首家市级民族管弦乐行业协会“苏州民族管弦乐艺术团队联合会”揭牌
                </div>
                <div class="mt-10 text-2">
                  全国首家市级民族管弦乐行业协会“苏州民族管弦乐艺术团队联合会”揭牌...
                </div>
                <div class="mt-10 text-3">2022.09.12</div>
              </div>
            </div>
            <div class="dynamic-item">
              <div class="dynamic-item-img">
                <img
                  src="@/assets/version2/artTeamUnion-12.png"
                  alt=""
                  srcset=""
                  class="img-default"
                />
              </div>
              <div class="dynamic-item-text t-l">
                <div class="text-1">
                  苏州成立全国首家民族管弦乐艺术团队联合会
                </div>
                <div class="mt-10 text-2">
                  12月15日，由苏州民族管弦乐团等单位发起、苏州市文化和民政部门批准的苏州市民族管弦乐艺...
                </div>
                <div class="mt-10 text-3">2020.12.15</div>
              </div>
            </div>
            <div class="dynamic-item">
              <div class="dynamic-item-img">
                <img
                  src="@/assets/version2/artTeamUnion-13.png"
                  alt=""
                  srcset=""
                  class="img-default"
                />
              </div>
              <div class="dynamic-item-text t-l">
                <div class="text-1">
                  指挥彭家鹏：建设好联合会，打造新时代中国苏州民族音乐文化品牌
                </div>
                <div class="mt-10 text-2">
                  首届江苏•苏州民族音乐展演，是我市民族音乐艺术事业为进一步适应新时代中国特色社会主义文化...
                </div>
                <div class="mt-10 text-3">2020.12.21</div>
              </div>
            </div>
          </div>
        </div>
        <!-- 展演 -->
        <div class="contents pt-87 activities-wrap">
          <div class="c-1 f-26 f-w-5 l-2 t-l">展演·活动征集</div>
          <div class="mt-32 flex between">
            <div class="activities-img">
              <img
                src="@/assets/version2/artTeamUnion-14.png"
                alt=""
                srcset=""
                class="img-default"
              />
            </div>
            <div class="activities-text t-l">
              <div class="text-1">
                通知丨第二届苏州市青少年民族器乐展演活动开始报名
              </div>
              <div class="text-2 mt-20">
                为全面贯彻党的美育方针，引领青少年树立正确的审美观念、陶冶情操、塑造美好心灵，
                进一步弘扬民族文化，繁荣江南丝竹艺术，用优秀民族艺术语言讲好华夏故事、弘扬民族精神。
                2021年首届“苏州市青少年民族器乐展演活动”吸引了近700名选手参加，群星闪耀，
                活动举办期间的照片直播关注点赞超8万人次，受到广大群众和....
              </div>
              <div class="flex between mt-26">
                <div class="text-3 inbl-m">2022-07-08</div>
                <div class="btn inbl-m">查看更多</div>
              </div>
            </div>
          </div>
          <div class="mt-32 line"></div>
        </div>
      </div>
    </div>
    <footNav />
  </div>
</template>

<script>
import headNav from "../components/header2.vue";
import footNav from "../components/footer2.vue";
export default {
  components: { headNav, footNav },
  data() {
    return {
      active: 1,
    };
  },
  methods: {
    change(type) {
      this.active = type;
    },
  },
};
</script>

<style scoped lang="scss">
.common-contents {
  background-color: #f2f6f7;
  padding-bottom: 330px;
  .banner {
    .img {
      width: 100%;
      max-height: 682px;
    }
  }
  .c-1 {
    color: #464646;
  }
  .c-2 {
    color: #999;
  }
  .l-2 {
    letter-spacing: 2px;
  }
  .contents {
    width: 1200px;
    margin: 0 auto;
  }
  .content-item {
    width: 315px;
    line-height: 60px;
    border: 1px solid #666666;
    color: #464646;
  }
  .content-item-acitve {
    color: #835c46;
    font-weight: bold;
    border: 1px solid #835c46;
    position: relative;
    &::after {
      content: "";
      width: 315px;
      height: 3px;
      background: #835c46;
      position: absolute;
      bottom: -3px;
      left: -1px;
      z-index: 10;
    }
  }
  .new-item {
    padding-bottom: 24px;
    border-bottom: 1px solid #e7e7e7;
    &:last-child {
      margin-bottom: 0 !important;
    }
  }
  .shell-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 447px;

    .shell {
      width: 100%;
      height: 447px;
      display: flex;
    }
    .box {
      flex: 1;
      overflow: hidden;
      transition: 0.5s;
      position: relative;
    }
    .box > img {
      width: 100%;
      height: 100%;
      /* 对图片进行剪切，保留原始比例 */
      object-fit: cover;
      transition: 0.5s;
    }
    .box > span {
      position: absolute;
      left: 21px;
      bottom: 11px;
      z-index: 10;
      font-size: 18px;
      color: #fff;
      opacity: 0;
      transition: 0.5s;
    }
    .box:hover {
      flex-basis: 36%;
    }
    .box:hover > img {
      width: 100%;
      height: 100%;
    }
    .box:hover > span {
      opacity: 1;
    }
  }
  .show-item {
    width: 1200px;
    height: 420px;
    border-radius: 24px;
    overflow: hidden;
    position: relative;
    .show-item-text {
      font-size: 26px;
      color: #ffffff;
      letter-spacing: 4px;
      position: absolute;
      left: 56px;
      top: 161px;
      z-index: 10;
      font-weight: 400;
    }
  }
  .dynamic-wrap {
    .dynamic-item {
      width: 378px;
      transition: transform 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      &-img {
        width: 378px;
        height: 220px;
      }
      &-text {
        padding: 16px;
        background: #fff;
        .text-1 {
          font-size: 18px;
          font-weight: 500;
          color: #4b4b4b;
          letter-spacing: 2px;
        }
        .text-2 {
          font-size: 14px;
          font-weight: 400;
          color: #666666;
          letter-spacing: 1px;
        }
        .text-3 {
          font-size: 15px;
          font-family: DIN, DIN-Regular;
          color: #666666;
        }
      }
      &:hover {
        box-shadow: 0 1px 3px rgba(64, 71, 75, 0.1),
          0 16px 32px -4px rgba(64, 71, 75, 0.1);
        transform: translateY(-1px);
      }
    }
  }
  .activities-wrap {
    .activities-img {
      width: 640px;
      height: 339px;
    }
    .activities-text {
      width: 560px;
      height: 339px;
      background: #ffffff;
      padding: 52px 39px 37px;
      .text-1 {
        font-size: 20px;
        color: #4b4b4b;
        letter-spacing: 2px;
      }
      .text-2 {
        font-size: 14px;
        font-weight: 300;
        color: rgba(64, 71, 75, 0.8);
        letter-spacing: 1px;
      }
      .text-3 {
        font-size: 14px;
        font-family: Roboto, Roboto-Light;
        font-weight: 300;
        line-height: 32px;
        color: rgba(64, 71, 75, 0.8);
      }
      .btn {
        width: 100px;
        height: 32px;
        border: 1px solid rgba(64, 71, 75, 0.3);
        line-height: 30px;
        text-align: center;
        font-size: 14px;
        color: rgba(64, 71, 75, 0.8);
      }
    }
    .line {
      width: 640px;
      height: 4px;
      background: rgba(131, 92, 70, 1);
      position: relative;
      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        right: 0;
        width: 440px;
        height: 4px;
        background: rgba(231, 231, 231, 1);
        z-index: 10;
      }
    }
  }
  .concert-wrap {
    .title {
      width: 875px;
      height: 72px;
      background: #835c46;
      font-size: 26px;
      font-weight: 500;
      color: #ffffff;
      line-height: 70px;
      letter-spacing: 2px;
      text-align: center;
    }
  }
}
</style>