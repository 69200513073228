<template>
  <div class="testRegistration-wrap">
    <headNav active="4"></headNav>
    <div style="margin: 20px 0; font-weight: bold; font-size: 20px">
      2021年苏州民族管弦乐团社会艺术水平考级报名表
    </div>
    <el-form :model="form" :rules="rules" ref="form">
      <table
        style="margin: 0 auto"
        border="1"
        width="1000px"
        align="center"
        cellspacing="0"
        cellpadding="0"
      >
        <tr align="center" bgcolor="#eee">
          <td colspan="7">考生个人信息</td>
        </tr>
        <tr align="center">
          <td bgcolor="#fff"><i class="tipColor">*&nbsp;</i>姓名</td>
          <td>
            <el-form-item prop="name"
              ><el-input v-model="form.name" placeholder="请输入"></el-input>
            </el-form-item>
          </td>
          <td bgcolor="#fff"><i class="tipColor">*&nbsp;</i>性别</td>
          <td>
            <el-form-item prop="sex">
              <el-select
                v-model="form.sex"
                placeholder="请选择"
                style="width: 100%"
              >
                <el-option label="男" value="1"></el-option>
                <el-option label="女" value="2"></el-option>
              </el-select>
            </el-form-item>
          </td>
          <td bgcolor="#fff"><i class="tipColor">*&nbsp;</i>民族</td>
          <td>
            <el-form-item prop="nation"
              ><el-input v-model="form.nation" placeholder="请输入"></el-input>
            </el-form-item>
          </td>
          <td colspan="2" rowspan="3">
            <simple-cropper
              :initParam="uploadParam"
              :successCallback="uploadHandle"
              ref="cropper"
              v-if="!form.idPhotoStr"
            >
              <div class="cur" @click="upload">
                <div>
                  <i class="el-icon-plus" style="font-size: 1.5rem"></i>
                </div>
                <div><i class="tipColor">*&nbsp;</i>一寸证件照</div>
                <div>（蓝底、白底均可）</div>
              </div>
            </simple-cropper>
            <div class="uploader-preview cur" v-else>
              <img :src="form.idPhotoStr" />
              <i
                class="el-icon-close deleteBtn"
                @click="deleteImg('idPhotoStr')"
              ></i>
            </div>
          </td>
        </tr>
        <tr align="center">
          <td bgcolor="#fff"><i class="tipColor">*&nbsp;</i>拼音</td>
          <td>
            <el-form-item prop="pinyin"
              ><el-input v-model="form.pinyin" placeholder="请输入"></el-input>
            </el-form-item>
          </td>
          <td bgcolor="#fff"><i class="tipColor">*&nbsp;</i>出生日期</td>
          <td>
            <el-form-item prop="birthDateTime">
              <el-date-picker
                type="date"
                placeholder="请选择"
                v-model="form.birthDateTime"
                :picker-options="pickerOptions"
                value-format="yyyy-MM-dd"
                style="width: 100%"
              ></el-date-picker>
            </el-form-item>
          </td>
          <td colspan="2"></td>
        </tr>
        <tr align="center">
          <td bgcolor="#fff"><i class="tipColor">*&nbsp;</i>证件类型</td>
          <td>
            <el-form-item prop="documentType">
              <el-select
                v-model="form.documentType"
                placeholder="请选择"
                style="width: 100%"
              >
                <el-option label="身份证" value="1"></el-option>
                <el-option label="护照/台胞证及其他" value="2"></el-option>
              </el-select>
            </el-form-item>
          </td>
          <td bgcolor="#fff"><i class="tipColor">*&nbsp;</i>证件号码</td>
          <td colspan="3">
            <el-form-item prop="idNumber"
              ><el-input v-model="form.idNumber" placeholder="请输入"></el-input>
            </el-form-item>
          </td>
        </tr>
        <tr align="center">
          <td bgcolor="#fff" rowspan="2">
            <i class="tipColor">*&nbsp;</i>乐理证书
          </td>
          <td>
            <el-form-item prop="isCertificate">
              <el-checkbox
                label="有"
                name="1"
                v-model="form.isCertificate1"
                @change="isCertificateChange($event, 1)"
              ></el-checkbox>
            </el-form-item>
          </td>
          <td bgcolor="#fff"><i class="tipColor">*&nbsp;</i>证书编号</td>
          <td colspan="5">
            <el-form-item
              ><el-input
                v-model="form.certificateNumber"
                :disabled="form.isCertificate2" placeholder="请输入"
              ></el-input>
            </el-form-item>
          </td>
        </tr>
        <tr align="center">
          <td>
            <el-form-item prop="isCertificate">
              <el-checkbox
                label="无"
                name="0"
                v-model="form.isCertificate2"
                @change="isCertificateChange($event, 2)"
              ></el-checkbox>
            </el-form-item>
          </td>
          <td bgcolor="#fff">
            <i class="tipColor">*&nbsp;</i>是否参加乐理考试
          </td>
          <td colspan="5" align="left">
            <el-form-item prop="isTakeTheExam">
              <el-radio-group v-model="form.isTakeTheExam">
                <el-radio label="1">是</el-radio>
                <el-radio label="0">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </td>
        </tr>
        <tr align="center">
          <td bgcolor="#fff"><i class="tipColor">*&nbsp;</i>家长姓名</td>
          <td>
            <el-form-item prop="parentName"
              ><el-input v-model="form.parentName" placeholder="请输入"></el-input>
            </el-form-item>
          </td>
          <td bgcolor="#fff"><i class="tipColor">*&nbsp;</i>家长手机号</td>
          <td colspan="2">
            <el-form-item prop="parentPhone"
              ><el-input v-model="form.parentPhone" placeholder="请输入"></el-input>
            </el-form-item>
          </td>
          <td bgcolor="#fff"><i class="tipColor">*&nbsp;</i>电子邮箱</td>
          <td>
            <el-form-item prop="parentEmail"
              ><el-input v-model="form.parentEmail" placeholder="请输入"></el-input>
            </el-form-item>
          </td>
        </tr>
        <tr align="center">
          <td bgcolor="#fff"><i class="tipColor">*&nbsp;</i>通讯地址</td>
          <td colspan="7">
            <el-form-item prop="address"
              ><el-input v-model="form.address" placeholder="请输入"></el-input>
            </el-form-item>
          </td>
        </tr>
        <tr align="center">
          <td bgcolor="#fff"><i class="tipColor">*&nbsp;</i>报名机构名称</td>
          <td colspan="7">
            <el-form-item prop="registrationAgencyName"
              ><el-input v-model="form.registrationAgencyName" placeholder="请输入"></el-input>
            </el-form-item>
          </td>
        </tr>
        <tr align="center">
          <td bgcolor="#fff"><i class="tipColor">*&nbsp;</i>教师姓名</td>
          <td>
            <el-form-item prop="teacherName"
              ><el-input v-model="form.teacherName" placeholder="请输入"></el-input>
            </el-form-item>
          </td>
          <td bgcolor="#fff"><i class="tipColor">*&nbsp;</i>教师手机号</td>
          <td colspan="5">
            <el-form-item prop="teacherPhone"
              ><el-input v-model="form.teacherPhone" placeholder="请输入"></el-input>
            </el-form-item>
          </td>
        </tr>
        <tr align="center" bgcolor="#eee">
          <td colspan="8">报考科目信息</td>
        </tr>
        <tr align="center">
          <td bgcolor="#fff"><i class="tipColor">*&nbsp;</i>报考科目</td>
          <td>
            <el-form-item prop="examSubjectId">
              <el-select
                v-model="form.examSubjectId"
                placeholder="请选择"
                style="width: 100%"
                @change="getLevel"
              >
                <el-option
                  :label="item.subject"
                  :value="item.id"
                  v-for="(item, index) in examSubjectIdOptions"
                  :key="index"
                ></el-option>
              </el-select>
            </el-form-item>
          </td>
          <td bgcolor="#fff"><i class="tipColor">*&nbsp;</i>报考级别</td>
          <td colspan="5">
            <el-form-item prop="examLevelId">
              <el-select
                v-model="form.examLevelId"
                placeholder="请选择"
                style="width: 100%"
                @change="examLevelChange"
              >
                <el-option
                  :label="item.level"
                  :value="item.id"
                  v-for="(item, index) in levelOptions"
                  :key="index"
                ></el-option>
              </el-select>
            </el-form-item>
          </td>
        </tr>
        <tr align="center">
          <td bgcolor="#fff"><i class="tipColor">*&nbsp;</i>报考曲目</td>
          <td colspan="7">
            <el-form-item prop="songCategory"
              ><el-input v-model="form.songCategory" placeholder="请输入"></el-input>
            </el-form-item>
          </td>
        </tr>
        <tr align="center" style="height: 200px">
          <td colspan="8">
            <div
              class="cur"
              style="width: 20rem; height: 15rem; padding-top: 6rem;"
              @click="fileUpload"
              v-if="!form.idCopyBase64"
            >
              <div>
                <i class="el-icon-plus" style="font-size: 1.5rem"></i>
              </div>
              <div><i class="tipColor">*&nbsp;</i>户口本或身份证复印件上传</div>
            </div>
            <div v-else class="file-preview cur">
              <img
                :src="form.idCopyBase64"
                alt=""
                style="width: 100%; height: 100%"
              />
              <i
                class="el-icon-close deleteBtn"
                @click="deleteImg('idCopyBase64')"
              ></i>
            </div>
            <input
              type="file"
              name="file"
              ref="idCopyBase64"
              @change="fileUploadChange"
              accept="image/*"
              v-show="false"
            />
          </td>
        </tr>
        <tr align="center">
          <td colspan="8">
            <div style="color: red">
              *&nbsp;考生报名信息将用于制作证书，认真核对填写内容，因填写错误造成后果，责任自负。
            </div>
          </td>
        </tr>
      </table>
      <div align="center" style="margin-top: 50px">
        <el-button type="primary" @click="submit" :loading="loading" :disabled="loading"
          >报名</el-button
        >
      </div>
    </el-form>
    <footNav style="margin-top: 100px" />
  </div>
</template>
<script>
import headNav from "../components/header2.vue";
import footNav from "../components/footer2.vue";
import SimpleCropper from "@/components/SimpleCropper";
export default {
  components: { headNav, footNav, SimpleCropper },
  data() {
    var validateIsCertificate = (rule, value, callback) => {
      if (this.form.isCertificate1 || this.form.isCertificate2) {
        callback();
      } else {
        callback(new Error("请选择"));
      }
    };
    var validateIdNumber = (rule, value, callback) => {
      const card = /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
      if (this.form.documentType == 1) {
        if (card.test(value)) {
          callback();
        } else {
          callback(new Error("请输入正确内容"));
        }
      } else if (this.form.documentType == 2) {
        if (value) {
          callback();
        } else {
          callback(new Error("请输入正确内容"));
        }
      } else {
        callback();
      }
    };
    var validatePhone = (rule, value, callback) => {
      const phone = /^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/;
      if (phone.test(value)) {
        callback();
      } else {
        callback(new Error("请输入正确内容"));
      }
    };
    var validateEmail = (rule, value, callback) => {
      const email = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
      if (email.test(value)) {
        callback();
      } else {
        callback(new Error("请输入正确内容"));
      }
    };
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      uploadParam: {
        fileType: "recruit", // 其他上传参数
        scale: 4, // 相对手机屏幕放大的倍数: 4倍
      },
      form: {},
      rules: {
        name: [{ required: true, message: "请输入", trigger: "blur" }],
        sex: [{ required: true, message: "请选择", trigger: "change" }],
        pinyin: [{ required: true, message: "请输入", trigger: "blur" }],
        nation: [{ required: true, message: "请输入", trigger: "blur" }],
        birthDateTime: [
          { required: true, message: "请选择", trigger: "change" },
        ],
        documentType: [
          { required: true, message: "请选择", trigger: "change" },
        ],
        idNumber: [{ validator: validateIdNumber, trigger: "blur" }],
        isCertificate: [
          {
            required: true,
            validator: validateIsCertificate,
            trigger: "change",
          },
        ],
        isTakeTheExam: [
          { required: true, message: "请选择", trigger: "change" },
        ],
        parentName: [{ required: true, message: "请输入", trigger: "blur" }],
        parentPhone: [{ validator: validatePhone, trigger: "blur" }],
        teacherPhone: [{ validator: validatePhone, trigger: "blur" }],
        parentEmail: [{ validator: validateEmail, trigger: "blur" }],
        address: [{ required: true, message: "请输入", trigger: "blur" }],
        registrationAgencyName: [
          { required: true, message: "请输入", trigger: "blur" },
        ],
        teacherName: [{ required: true, message: "请输入", trigger: "blur" }],
        examSubjectId: [
          { required: true, message: "请选择", trigger: "change" },
        ],
        examLevelId: [{ required: true, message: "请选择", trigger: ["blur",'change'] }],
        songCategory: [{ required: true, message: "请输入", trigger: "blur" }],
      },
      examSubjectIdOptions: [],
      levelOptions: [],
      loading: false,
    };
  },
  mounted() {
    this.$router.afterEach(() => {
      window.scrollTo(0, 0);
    });
    // 获取科目
    this.getExamSubject();
  },
  methods: {
    // 获取科目
    getExamSubject() {
      this.$ajax
        .get(this.$ajax.localOrOnline() + "examRegistration/getExamSubject")
        .then((res) => {
          this.examSubjectIdOptions = res.data || [];
        })
        .catch((err) => {
          this.$message(err.message);
        });
    },
    // 获取级别
    getLevel() {
      // 根据不同科目切换不同级别
      this.form.examLevelId = '';
      this.levelOptions = [];
      this.$ajax
        .get(this.$ajax.localOrOnline() + "examRegistration/getExamLevel", {
          examSubjectId: this.form.examSubjectId,
        })
        .then((res) => {
          this.levelOptions = res.data || [];
        })
        .catch((err) => {
          this.$message(err.message);
        });
    },
    examLevelChange(val) {
      this.$set(this.form, "examLevelId", val);
      this.form = JSON.parse(JSON.stringify(this.form));
    },
    // 乐理证书选择
    isCertificateChange(val, type) {
      if (type == 1) {
        this.form.isCertificate2 = false;
      } else {
        this.form.isCertificate1 = false;
        this.form.certificateNumber = "";
      }
    },
    // 户口本上传
    fileUpload() {
      this.$refs.idCopyBase64.click();
    },
    fileUploadChange(event) {
      let file = event.target.files[0];
      let _this = this;
      var reader = new FileReader();
      //通过文件流将文件转换成Base64字符串
      reader.readAsDataURL(file);
      reader.onload = function () {
        _this.form.idCopyBase64 = reader.result;
        _this.form = JSON.parse(JSON.stringify(_this.form));
      };
    },
    // 删除图片
    deleteImg(type) {
      this.form[type] = "";
      if (type == "idCopyBase64") {
        this.$refs[type].value = "";
      }
    },
    // 上传证件照
    upload() {
      this.$refs["cropper"].upload();
    },
    // 上传证件照成功回调
    uploadHandle(fileName, imgSrc) {
      this.form.idPhotoOldFileName = fileName;
      this.form.idPhotoStr = imgSrc;
      this.form = JSON.parse(JSON.stringify(this.form));
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.form.isCertificate1 && !this.form.certificateNumber) {
            this.$message.error("请输入证书编号");
            return;
          }
          if (!this.form.idPhotoStr) {
            this.$message.error("请上传证件照");
            return;
          }
          if (!this.form.idCopyBase64) {
            this.$message.error("请上传户口本或身份证复印件照片");
            return;
          }
          if (this.loading) {
            return;
          }
          this.$confirm(
            "考生报名信息将用于制作证书，认真核对填写内容，因填写错误造成后果，责任自负。",
            "提示",
            {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            }
          ).then(() => {
            this.loading = true;
            // 最终提交的数据
            this.form.idPhoto = this.form.idPhotoStr.split(";base64,")[1];
            this.form.isCertificate1
              ? (this.form.isCertificate = 1)
              : (this.form.isCertificate = 0);
            this.form.idCopy = document.getElementsByName("file")[0].files[0];
            let data = new FormData();
            for (const key in this.form) {
              data.append(key, this.form[key]);
            }
            var configs = {
              headers: {
                "Content-Type": "multipart/form-data"
              },
            };
            this.$ajax
              .post(this.$ajax.localOrOnline() + "examRegistration/registration", data, configs)
              .then((res) => {
                if (res.code == 200) {
                  this.$alert("报名成功", {
                    confirmButtonText: "确定",
                    callback: () => {
                      location.reload();
                    },
                  });
                } else {
                  this.loading = false;
                  this.$message(res.message);
                }
              })
              .catch((err) => {
                this.loading = false;
                this.$message(err.message);
              });
          });
        } else {
          this.$message.error("请检查表单是否正确填写");
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
tr {
  height: 70px;
}

td {
  width: 100px;
  padding: 0 5px;
}
.el-form-item--mini.el-form-item,
.el-form-item--small.el-form-item,
.el-form-item {
  margin-bottom: 2px;
}
.cur {
  cursor: pointer;
}
.uploader-preview {
  width: 71px;
  height: 99px;
  img {
    width: 100%;
    height: 100%;
  }
}
.file-preview {
  width: 20rem;
  height: 15rem;
}
.tipColor {
  color: red;
}
.uploader-preview,
.file-preview {
  position: relative;
  .deleteBtn {
    display: inline-block;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 100;
    width: 20px;
    height: 20px;
    border-radius: 0 0 0 80%;
    background: #000;
    color: #fff;
    line-height: 18px;
    padding-left: 3px;
  }
}
</style>