<template>
  <div class="common-wrap">
    <headNav active="1"></headNav>
    <showHeader :showHeaderIndex="3" @showHeaderChange="showHeaderChange" />
    <div class="common-contents">
      <div class="banner">
        <a-icon type="left" class="carousel-prev-btn" @click="handlePrev" />
        <a-carousel dotsClass="home-dots" :autoplay="true" ref="carousel">
          <div v-for="(item, key) in 7" :key="key">
            <img
              class="carousel-pic"
              :src="require('../assets/version2/show-' + (key + 1) + '.png')"
              alt=""
            />
          </div>
        </a-carousel>
        <a-icon type="right" class="carousel-next-btn" @click="handleNext" />
      </div>
      <div class="contents">
        <div class="bg bg-1"></div>
        <div class="bg bg-2"></div>
        <div class="block-1 flex between">
          <div class="left-img"></div>
          <div class="left"></div>
          <div class="right ml-60 tal">
            <div class="title-1" v-if="$i18n.locale == 'zh'">2018 年</div>
            <div
              class="title-2"
              :style="{ color: $i18n.locale == 'en' ? '#444444' : '' }"
            >
              {{
                $i18n.locale == "zh"
                  ? "美国波特兰文化交流展演"
                  : "America tour 2018"
              }}
            </div>
            <div class="line line-1 mt-30"></div>
            <div class="line line-2"></div>
            <div class="f-16 t-c2 f-w-3 mt-20">
              <div v-if="$i18n.locale == 'zh'">
                美国时间2018年7月27日、7月28日晚，成立仅半年多的苏州民族管弦乐团第一次走出国门，在美国波特兰州立大学林肯音乐厅奏响首场《华乐苏韵》音乐会，而这也是林肯音乐厅首次迎来中国的民族管弦乐团。这一夜，展示中华民族文化的乐符在音乐大厅上空久久回荡；这一夜，音乐成为中西文化交流、增进双方和平友谊最好的桥梁；这一夜，苏州向世界亮出了靓丽的文化新名片，更让世界感受到了中华文化的魅力，尽显民族的文化自信。
              </div>
              <div class="mt-20" v-if="$i18n.locale == 'zh'">
                2018年是苏州市与美国波特兰市结为友好城市30周年，一曲热烈欢腾的《庆典序曲》，锣鼓、唢呐齐鸣，为美国观众开启了东方音乐之旅的大门。本次演出选取的六支曲目都是乐团经典保留曲目，在中西方文化的激烈碰撞中，拉近了美国观众与中国传统文化间的距离。“民族+管弦是对民族音乐的传承与创新，也是对国际文化的尊重与接轨。”乐团指挥彭家鹏说，“这次音乐会不仅将这支代表苏州、代表中国文化和地域文化的年轻乐团介绍给了波特兰观众，更将中华民族的音乐之美展现给美国观众。”
              </div>
              <div class="fm-r mt-20">
                On the evening of July 27 and 28, 2018, the Suzhou Chinese
                Orchestra, which was established for just over half a year, went
                abroad for the first time and performed the "Charms of Chinese
                Music" in the Lincoln Hall of Portland State University in the
                United States. This was also the first time that Lincoln Hall
                welcomed a Chinese orchestra. On this night, musical notes
                showing the Chinese culture reverberated over the music hall for
                a long time; this night, music became the best bridge for
                cultural exchanges between China and the West and the promotion
                of peace and friendship between the two sides; this night,
                Suzhou showed a beautiful new cultural business card to the
                world. It also let the world feel the charm of Chinese culture
                and showed the cultural self-confidence of the nation.
              </div>
              <div class="fm-r mt-20">
                2018 was the 30th anniversary of the sister city friendship
                between Suzhou and Portland. An enthusiastic and jubilant song
                "Celebration Overture", with gongs, drums and suona, opened the
                door for American audiences to a journey of Eastern music. The
                six repertoires selected for this performance were all classic
                repertoires of the orchestra. In the collision of Chinese and
                Western cultures, the distance between the American audience and
                the traditional Chinese culture has been narrowed. "National +
                Orchestra is the inheritance and innovation of national music,
                but also the respect and integration of international culture."
                Orchestra conductor Pang Kapang said, "This concert not only
                introduces this young orchestra that represents Suzhou, Chinese
                culture and regional culture to the Portland audience, but also
                also shows the beauty of the Chinese music to the American
                audience.”
              </div>
            </div>
          </div>
        </div>
        <div class="block-2 tal">
          <div class="title-wrap">
            <div class="title">
              {{ $i18n.locale == "en" ? "Program" : "曲目" }}
            </div>
            <div class="title-en fm-r">PROGRAM</div>
          </div>
          <div class="flex mt-40 f-16 text-wrap">
            <div v-if="$i18n.locale == 'zh'">
              <div>
                <div>一.《庆典序曲》</div>
                <div class="t-c3 f-w-3">赵季平 曲</div>
              </div>
              <div class="mt-30">
                <div>二.《肯塔基的故乡与苏珊娜》</div>
                <div class="t-c3 f-w-3">美国民歌 彭修文 改编</div>
              </div>
              <div class="mt-30">
                <div>三.《庄周梦》</div>
                <div class="t-c3 f-w-3">赵季平曲 大提琴：康乔瑄（特邀）</div>
              </div>
              <div class="mt-30">
                <div>——中场休息——</div>
              </div>
              <div class="mt-30">
                <div>四.《坎地德序曲》</div>
                <div class="t-c3 f-w-3">伯恩斯坦 曲 张大森改编</div>
              </div>
              <div class="mt-30">
                <div>五.昆曲与民族管弦乐《牡丹亭·游园》</div>
                <div class="t-c3 f-w-3">朱昌耀 编曲 演唱：王芳（特邀）</div>
              </div>
              <div class="mt-30">
                <div>六.《抒情变奏曲》</div>
                <div class="t-c3 f-w-3">刘长远曲</div>
              </div>
              <div class="mt-30">
                <div>七.《澳门随想曲》</div>
                <div class="t-c3 f-w-3">王丹红曲</div>
              </div>
              <div class="mt-30">
                <div>指挥：彭家鹏</div>
                <div>演奏：苏州民族管弦乐团</div>
              </div>
            </div>
            <div class="fm-r" :class="$i18n.locale == 'zh' ? 'ml-60' : ''">
              <div>
                <div>1.「Festival Overture」</div>
                <div class="t-c3 f-w-3">Composed by Zhao Jiping</div>
              </div>
              <div class="mt-30">
                <div>2.「Kentucky Home and Susanna」</div>
                <div class="t-c3 f-w-3">
                  American Folk Song, Adapted by Peng Xiuwen
                </div>
              </div>
              <div class="mt-30">
                <div>3.Cello concerto「Butterfly Dream」</div>
                <div class="t-c3 f-w-3">
                  Composed by Zhao Jiping Cello Soloist: Chiao-Hsuan Kang
                  (Guest)
                </div>
              </div>
              <div class="mt-30">
                <div>——Intermission——</div>
              </div>
              <div class="mt-30">
                <div>4. Candide Overture</div>
                <div class="t-c3 f-w-3">
                  Composed by Leonard Bernstein Adapted by Zhang Dasen
                </div>
              </div>
              <div class="mt-30">
                <div>
                  5.「Peony Pavilion·Strolling in the Garden」（Kunqu & Chinese
                  Orchestra）
                </div>
                <div class="t-c3 f-w-3">
                  Composed by Zhu Changyao Singer: Wang Fang (Guest)
                </div>
              </div>
              <div class="mt-30">
                <div>6. Lyrical Variations</div>
                <div class="t-c3 f-w-3">Composed by Liu Changyuan</div>
              </div>
              <div class="mt-30">
                <div>7. Macau Capriccio</div>
                <div class="t-c3 f-w-3">Composed by Wang Danhong</div>
              </div>
              <div class="mt-30">
                <div>Conductor: Pang Kapang</div>
                <div>Performed by Suzhou Chinese Orchestra</div>
              </div>
            </div>
          </div>
        </div>
        <div class="block-2 tal">
          <div class="title-wrap">
            <div class="title">
              {{ $i18n.locale == "en" ? "Press" : "相关报道" }}
            </div>
            <div class="title-en fm-r">PRESS</div>
          </div>
          <div class="mt-90 flex center">
            <div class="report report-1">
              <div class="re-img"></div>
              <div class="re-text" v-if="$i18n.locale == 'zh'">
                2018年9月3日《参考消息》<br />中国民族音乐倾倒美国乐迷
              </div>
              <div class="re-text" v-else style="padding-top: 25px;">
                September 3, 2018<br />"Reference News"<br />Chinese folk
                music<br />captivates American fans
              </div>
            </div>
            <div class="report report-2">
              <div class="re-img"></div>
              <div class="re-text">China Daily REPORT</div>
            </div>
          </div>
        </div>
        <div class="video-wrap">
          <video
            class="video"
            controls
            poster="../assets/version2/show-main-4.png"
            src="https://suco.oss-cn-hangzhou.aliyuncs.com/home/web/002.mp4"
          >
            your browser does not support the video tag
          </video>
        </div>
      </div>
    </div>
    <footNav />
  </div>
</template>

<script>
import headNav from "../components/header2.vue";
import footNav from "../components/footer2.vue";
import showHeader from "../components/showHeader.vue";
export default {
  components: { headNav, footNav, showHeader },
  methods: {
    handlePrev() {
      this.$refs.carousel.prev();
    },
    handleNext() {
      this.$refs.carousel.next();
    },
    // tabs 切换
    showHeaderChange(index) {
      if (index != 3) {
        if (index == 4) {
          this.$router.push({ name: "europe" });
        } else {
          this.$router.push({ name: "othershow", query: { type: index } });
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
@keyframes anim {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.common-contents {
  background-color: #f2f6f7;
  // 轮播图
  .banner {
    max-height: 695px;
    overflow: hidden;
    position: relative;
    .carousel-prev-btn,
    .carousel-next-btn {
      display: none;
      font-size: 80px;
      color: #f2f6f7;
      position: absolute;
      top: 300px;
      z-index: 10;
      width: 80px;
      height: 80px;
      animation: anim 0.4s;
    }
    .carousel-prev-btn {
      left: 0;
    }
    .carousel-next-btn {
      right: -0;
    }
    &:hover {
      .carousel-prev-btn,
      .carousel-next-btn {
        display: block;
      }
    }
    .ant-carousel .slick-slide {
      text-align: center;
      background: #131419;
      overflow: hidden;
      height: auto;
    }

    .carousel-pic {
      width: 100%;
      height: auto;
      margin: 0 auto;
    }
  }
  .contents {
    padding: 160px 0;
    position: relative;
    .bg {
      position: absolute;
      right: 0;
      z-index: 10;
      background-size: 100% 100%;
    }
    .bg-1 {
      width: 207px;
      height: 900px;
      background-image: url("../assets/version2/show-bg-1.png");
      top: 671px;
    }
    .bg-2 {
      width: 822px;
      height: 1221px;
      background-image: url("../assets/version2/show-bg-2.png");
      top: 1149px;
    }
    .block-1 {
      width: 1200px;
      margin: 0 auto;
      position: relative;
      .left-img {
        background-image: url("../assets/version2/show-text-1.png");
        background-size: 100% 100%;
        width: 85px;
        height: 130px;
        position: absolute;
        left: -120px;
        top: 0;
      }
      .left {
        width: 570px;
        height: 824px;
        background-image: url("../assets/version2/show-main-1.png");
        background-size: 100% 100%;
      }
      .right {
        width: 630px;
        .title-1 {
          font-size: 48px;
          font-family: "Microsoft YaHei";
          color: #444444;
          margin-top: -15px;
        }
        .title-2 {
          font-size: 36px;
          color: #634636;
          letter-spacing: 5px;
        }
        .line {
          width: 100%;
          background: #c7c1bd;
        }
        .line-1 {
          height: 4px;
        }
        .line-2 {
          height: 2px;
          margin-top: 5px;
        }
      }
    }
    .block-2 {
      width: 1200px;
      margin: 0 auto;
      margin-top: 166px;
      .title-wrap {
        position: relative;
        .title {
          width: 290px;
          height: 53px;
          background: linear-gradient(
            90deg,
            #b49a71 0%,
            rgba(180, 154, 113, 0) 100%
          );
          padding: 10px 0 10px 27px;
          font-size: 24px;
          font-family: Source Han Sans CN, Source Han Sans CN-Regular;
          font-weight: 400;
          color: #634636;
        }
        .title-en {
          font-size: 100px;
          font-weight: bolder;
          -webkit-text-stroke: 1px rgb(196, 196, 196);
          color: transparent;
          position: absolute;
          right: 0;
          top: -50px;
          z-index: 10;
        }
      }
      .text-wrap {
        color: #000;
      }
      .report {
        width: 515px;
        .re-img {
          background-size: 100% 100%;
          height: 595px;
        }
        .re-text {
          height: 192px;
          background: #b49a71;
          font-size: 24px;
          font-family: Source Han Sans CN, Source Han Sans CN-Regular;
          font-weight: 400;
          text-align: CENTER;
          color: #ffffff;
          letter-spacing: 2px;
        }
      }
      .report-1 {
        .re-img {
          background-image: url("../assets/version2/show-main-2.png");
        }
        .re-text {
          border-radius: 0 0 80px 0;
          padding: 47px 70px 0;
        }
      }
      .report-2 {
        margin-left: 108px;
        .re-img {
          background-image: url("../assets/version2/show-main-3.png");
        }
        .re-text {
          border-radius: 0 0 0 80px;
          padding: 66px 0 0;
        }
      }
    }
    .video-wrap {
      width: 1200px;
      margin: 0 auto;
      height: 577px;
      margin-top: 106px;
      .video {
        width: 100%;
        height: 100%;
        object-fit: fill;
      }
    }
  }
}
</style>
<style lang="scss">
.ant-carousel .home-dots {
  bottom: 70px;
}
</style>