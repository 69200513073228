<template>
  <!-- 公益惠民 -->
  <div class="common-wrap" :class="$i18n.locale == 'en' ? 'en-home-wrap' : ''">
    <headNav active="1"></headNav>
    <div class="common-contents">
      <div class="banner">
        <img
          src="@/assets/version2/other-32.png"
          alt=""
          srcset=""
          class="img"
          v-if="$i18n.locale == 'zh'"
        />
        <img
          src="@/assets/version2/otherEn-32.png"
          alt=""
          srcset=""
          class="img"
          v-else
        />
      </div>
      <div class="contents">
        <!-- tab -->
        <div class="tabs flex between w1200">
          <div
            class="tab-item"
            :class="[
              currentTabIndex == index ? 'tab-item-active' : '',
              'tab-item-' + index,
            ]"
            v-for="(item, index) in tabsList"
            :key="index"
            @click="changeTab(index)"
          >
            {{ item.text1 }}
          </div>
        </div>
        <div class="main-item">
          <div class="dot dot-1"></div>
          <main-title
            :name="$t('publicBenefit.concert.text1')"
            width="355"
            height="42"
            :src="require('../assets/version2/other-33.png')"
            v-if="currentTabIndex == 0"
          ></main-title>
          <main-title
            :name="$t('publicBenefit.forum.text1')"
            width="475"
            height="33"
            :src="require('../assets/version2/other-34.png')"
            v-if="currentTabIndex == 1"
          ></main-title>
          <main-title
            :name="$t('publicBenefit.school.text1')"
            width="555"
            height="42"
            :src="require('../assets/version2/other-35.png')"
            v-if="currentTabIndex == 2"
          ></main-title>
          <div class="w1200 tal">
            <div class="mt36 tab-subhead">
              {{ tabsList[currentTabIndex].text2 }}
            </div>
            <div class="list-wrap">
              <div
                class="list-item flex between"
                v-for="(item, index) in list"
                :key="index"
              >
                <img
                  :src="`${state.baseUrl}${item.thumb}`"
                  alt=""
                  class="left-img"
                />
                <div class="right-info">
                  <div class="info-title ellipsis">
                    {{ item.title }}
                  </div>
                  <div class="info-text ellipsis5">
                    {{ item.description }}
                  </div>
                  <div class="info-date">
                    {{ templateTime(item.inputtime) }}
                  </div>
                  <div class="info-btn">
                    <div class="btn" @click="viewMore(item)">
                      <span>查看更多</span>
                      <img
                        src="@/assets/version2/arrow-1.png"
                        alt=""
                        srcset=""
                        class="arrow"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div align="right">
              <el-pagination layout="prev, pager, next" :total="50" :background="true">
            </el-pagination>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <footNav />
  </div>
</template>

<script>
import headNav from "../components/header2.vue";
import footNav from "../components/footer2.vue";
import mainTitle from "../components/main-title/index.vue";
import moment from  'moment'
export default {
  components: { headNav, footNav, mainTitle },
  data() {
    return {
      currentTabIndex: 0,
      tabsList: [
        {
          text1: this.$t("publicBenefit.concert.text1"),
          text2: this.$t("publicBenefit.concert.text2"),
        },
        {
          text1: this.$t("publicBenefit.forum.text1"),
          text2: this.$t("publicBenefit.forum.text2"),
        },
        {
          text1: this.$t("publicBenefit.school.text1"),
          text2: this.$t("publicBenefit.school.text2"),
        },
      ],
      list: [],
    };
  },
  computed: {
    state() {
      return this.$store.state;
    },
  },
  mounted() {
    this.currentTabIndex = this.$route.query.type || 0;
    this.getData();
  },
  methods: {
    templateTime(time) {
      if (!time) return;
      return moment(time * 1000).format("YYYY-MM-DD");
    },
    changeTab(index) {
      this.currentTabIndex = index;
      this.getData();
    },
    getData() {
      this.$ajax
        .get("/cms/api/huiminlist", {
          gtype: this.currentTabIndex,
        })
        .then((res) => {
          if (res.code == "200") {
            this.list = res.data || [];
          }
        });
    },
    // 查看详情
    viewMore(item) {
      this.$router.push({ name: "news-detail", params: { id: item.id }, query: {type: 'pinpaigongyi'}});
    }
  },
};
</script>

<style scoped lang="scss">
.common-contents {
  background-color: #f2f6f7;
  .banner {
    .img {
      width: 100%;
      max-height: 682px;
    }
  }
  .main-item {
    position: relative;
    .dot {
      position: absolute;
      z-index: 99;
      background-image: url("../assets/version2/other-13.png");
      background-size: 100% 100%;
    }
    .dot-1 {
      width: 255px;
      height: 438px;
      top: -100px;
      right: 0;
      transform: rotateY(180deg);
    }
  }
  .contents {
    padding: 58px 0 130px;
  }
  .tabs {
    .tab-item {
      line-height: 80px;
      border: 1px solid #835c46;
      font-size: 24px;
      font-weight: 300;
      color: #634636;
      text-align: center;
      border-radius: 30px 0 30px 0;
      cursor: pointer;
    }
    .tab-item-0 {
      width: 220px;
      height: 80px;
    }
    .tab-item-1 {
      width: 440px;
      height: 80px;
    }
    .tab-item-2 {
      width: 266px;
      height: 80px;
    }
    .tab-item-active {
      background: #835c46;
      font-size: 28px;
      font-weight: 400;
      color: #ffffff;
    }
    .tab-subhead {
      font-size: 18px;
      font-weight: 300;
      color: #333333;
      line-height: 28px;
    }
  }
  .list-wrap {
    margin-top: 70px;
    .list-item {
      margin-bottom: 48px;
      .left-img {
        width: 637px;
        height: 390px;
      }
      .right-info {
        width: 563px;
        height: 390px;
        background: #fff;
        padding: 52px 31px;
        .info-title {
          font-size: 18px;
          font-weight: 400;
          color: #2b3033;
        }
        .info-text {
          font-size: 14px;
          font-weight: 300;
          color: rgba(64, 71, 75, 0.8);
          margin-top: 24px;
          line-height: 25px;
        }
        .info-date {
          font-size: 14px;
          font-family: Roboto, Roboto-Light;
          font-weight: 300;
          color: rgba(64, 71, 75, 0.8);
          margin-top: 40px;
        }
        .info-btn {
          text-align: right;
          margin-top: 29px;
          .btn {
            cursor: pointer;
            display: inline-block;
            width: 120px;
            line-height: 40px;
            border: 1px solid rgba(64, 71, 75, 0.3);
            font-size: 14px;
            font-weight: 400;
            text-align: center;
            color: rgba(64, 71, 75, 0.8);
            vertical-align: middle;
          }
          .arrow {
            width: 19px;
            height: 4px;
            vertical-align: middle;
            margin-left: 8px;
          }
        }
      }
    }
  }
}
.en-home-wrap {
  .common-contents {
    .tabs {
      .tab-item,
      .tab-item-active {
        font-size: 14px;
      }
    }
  }
}
</style>