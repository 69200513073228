<template>
  <div class="v-simple-cropper">
    <slot>
      <button @click="upload">上传图片</button>
    </slot>
    <div class="v-cropper-layer" ref="layer">
      <div class="layer-header">
        <button class="cancel" @click="cancelHandle">取消</button>
        <button class="confirm" @click="confirmHandle">裁剪</button>
      </div>
      <img ref="cropperImg" />
    </div>
    <input
      class="file"
      ref="file"
      type="file"
      accept="image/*"
      @change="uploadChange"
    />
  </div>
</template> 
 
<script>
import Cropper from "cropperjs";
import "cropperjs/dist/cropper.min.css";
export default {
  name: "v-simple-cropper",
  props: {
    initParam: Object,
    successCallback: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      cropper: {},
      filename: "",
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    // 初始化裁剪插件
    init() {
      let cropperImg = this.$refs["cropperImg"];
      this.cropper = new Cropper(cropperImg, {
        aspectRatio: 295 / 413,
        dragMode: "move",
        cropBoxMovable: false, //是否允许拖动裁剪框
        cropBoxResizable: false, //是否允许拖动 改变裁剪框大小
      });
    },
    // 点击上传按钮
    upload() {
      this.$refs["file"].click();
    },
    // 选择上传文件
    uploadChange(e) {
      let file = e.target.files[0];
      this.filename = file["name"];
      let URL = window.URL || window.webkitURL;
      this.$refs["layer"].style.display = "block";
      this.cropper.replace(URL.createObjectURL(file));
    },
    // 取消上传
    cancelHandle() {
      this.cropper.reset();
      this.$refs["layer"].style.display = "none";
      this.$refs["file"].value = "";
    },
    // 确定上传
    confirmHandle() {
      let cropBox = this.cropper.getCropBoxData();
      console.log(cropBox);
      let scale = this.initParam["scale"] || 1;
      console.log(scale);
      let cropCanvas = this.cropper.getCroppedCanvas({
        width: cropBox.width * scale,
        height: cropBox.height * scale,
      });
      let data = cropCanvas.toDataURL("image/jpeg");
      this.successCallback(this.filename, data);
      this.cancelHandle();
    },
  },
};
</script> 
 
<style scopd>
.file {
  display: none;
}
.v-cropper-layer {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  z-index: 99999;
  display: none;
}
.layer-header {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99999;
  background: #fff;
  width: 100%;
  box-sizing: border-box;
}
.cancel,
.confirm {
  font-size: 0.28rem;
  background: inherit;
  border: 0;
  outline: 0;
  display: inline-block;
  margin: 0 150px;
  background: #eee;
  padding: 10px 20px;
  cursor: pointer;
}
</style> 
