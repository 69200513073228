<template>
  <!-- 品牌活动 -->
  <div class="common-wrap">
    <headNav active="1"></headNav>
    <div class="common-contents">
      <div class="banner">
        <img
          src="@/assets/version2/other-27.png"
          alt=""
          srcset=""
          class="img"
          v-if="$i18n.locale == 'zh'"
        />
        <img
          src="@/assets/version2/otherEn-27.png"
          alt=""
          srcset=""
          class="img"
          v-else
        />
      </div>
      <div class="w1200 main">
        <div class="title">
          <div>
            {{ detailInfo.description }}
          </div>
          <span class="mt16 date">{{
            templateTime(detailInfo.inputtime)
          }}</span>
        </div>
        <div class="contents" v-html="contents"></div>
      </div>
    </div>
    <footNav />
  </div>
</template>

<script>
import headNav from "../components/header2.vue";
import footNav from "../components/footer2.vue";
import moment from "moment";
export default {
  components: { headNav, footNav },
  data() {
    return {
      id: "",
      detailInfo: {},
    };
  },
  computed: {
    state() {
      return this.$store.state;
    },
    contents() {
      let content = this.detailInfo.content || "";
      let newStr = content.replace(
        /<img [^>]*src=['"]([^'"]+)[^>]*>/gi,
        function (match, capture) {
          if (!/http|https/.test(capture)) {
            return `<img src="https://www.suzhousco.com${capture}" alt="" style="max-width: 100%;" />`;
          } else {
            return (
              '<img src="' + capture + '" alt="" style="max-width: 100%;" />'
            );
          }
        }
      );
      return newStr;
    },
  },
  created() {
    this.id = this.$route.query.type || "";
    this.getNewsDetail();
  },
  methods: {
    // 获取演出列表
    getNewsDetail() {
      this.$ajax
        .get("/cms/api/pinpaidetail", {
          id: this.id,
        })
        .then((res) => {
          if (res.code == "200") {
            this.detailInfo = res;
          }
        });
    },
    templateTime(time) {
      if (!time) return;
      return moment(time * 1000).format("YYYY-MM-DD");
    },
  },
};
</script>

<style scoped lang="scss">
.common-contents {
  background-color: #f2f6f7;
  .banner {
    .img {
      width: 100%;
      max-height: 682px;
    }
  }
  .main {
    padding: 94px 0 272px;
    .title {
      font-size: 36px;
      font-weight: 300;
      color: #333333;
      line-height: 56px;
      letter-spacing: 2px;
      text-align: center;
      padding: 0 0 12px 0;
      border-bottom: 1px solid #e4e4e4;
      margin-bottom: 36px;
      .date {
        font-size: 16px;
        font-weight: 300;
        text-align: left;
        color: #666666;
        line-height: 26px;
        letter-spacing: 2px;
      }
    }
  }
}
</style>
