<template>
  <div class="scoreInquiry-wrap">
    <headNav active="4"></headNav>
    <div class="contents w1200 mt-80">
      <div class="title-wrap">
        <div class="le-2">{{ titleObj.title1 }}</div>
        <div class="mt-15 title le-2">{{ titleObj.title2 }}</div>
      </div>
      <div class="name tal">姓名：{{ form.name }}</div>
      <!-- 社会水平考级 -->
      <div class="info" v-if="form.type == 'testScoreInquiry'">
        <table class="table" border="1">
          <tr>
            <td>报考科目</td>
            <td>报考级别</td>
            <td>报考曲目</td>
            <td>等第</td>
          </tr>
          <tr v-for="(item, index) in scoreList" :key="index">
            <td>{{ item.examSubject }}</td>
            <td>{{ item.examLevel }}</td>
            <td>{{ item.songCategory }}</td>
            <td>{{ item.score }}</td>
          </tr>
        </table>
      </div>
      <!-- 青少年大赛 -->
      <div class="info" v-else>
        <table class="table" border="1">
          <tr>
            <td>演奏曲目</td>
            <td>最终得分</td>
            <td>奖项</td>
          </tr>
          <tr v-for="(item, index) in scoreList" :key="index">
            <td>{{ item.songName }}</td>
            <td>{{ item.score }}</td>
            <td>{{ item.awards }}</td>
          </tr>
        </table>
      </div>
    </div>
    <footNav />
  </div>
</template>
<script>
import headNav from "../components/header2.vue";
import footNav from "../components/footer2.vue";
export default {
  components: { headNav, footNav },
  data() {
    return {
      form: {
        name: "",
        idNumber: "",
        type: "",
      },
      scoreList: [],
      titleObj: {
        title1: "",
        title2: "",
      },
    };
  },
  mounted() {
    this.form = this.$route.query;
    if (this.form.type == "teenagerScoreInquiry") {
      this.titleObj = {
        title1: "第二届苏州市青少年民族器乐展演",
        title2: "成绩查询",
      };
    } else if (this.form.type == "testScoreInquiry") {
      this.titleObj = {
        title1: "2022年苏州民族管弦乐团",
        title2: "社会艺术水平考级成绩查询",
      };
    }
    // 成绩查询
    this.scoreInquirySearch();
  },
  methods: {
    // 成绩查询
    scoreInquirySearch() {
      let ajaxUrl = "";
      if (this.form.type == "testScoreInquiry") {
        ajaxUrl = "examRegistration/getScore";
      } else {
        ajaxUrl = "instrument/competition/getInstrumentScore";
      }
      this.$ajax
        .get(this.$ajax.localOrOnline() + ajaxUrl, this.form)
        .then((res) => {
          if (res.code == 200) {
            if (res.data && res.data.length) {
              this.scoreList = res.data;
            } else {
              this.$alert("没有获取到您的考试信息", {
                confirmButtonText: "确定",
              });
            }
          } else {
            this.$message(res.message);
          }
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.contents {
  font-family: Source Han Sans CN, Source Han Sans CN-Regular;
  background-image: url("../assets/version2/peixun-20.png");
  background-size: 100% 100%;
  padding: 133px 0 400px;
  margin-bottom: 464px;
  .title-wrap {
    font-size: 34px;
    font-weight: 400;
    text-align: center;
    color: #444444;
    .title {
      font-size: 36px;
      color: #845c46;
    }
  }
  .name {
    width: 940px;
    font-size: 16px;
    font-weight: 400;
    color: #444444;
    margin: 113px auto 31px;
  }
  .info {
    .table {
      width: 950px;
      margin: 0 auto;
      background: #fffaf6;
      border: 1px solid #979797;
      font-size: 16px;
      font-weight: 400;
      color: #444444;
      td {
        width: 313px;
        height: 100px;
        text-align: center;
      }
    }
  }
}
</style>