<template>
  <div class="testUserInfo-wrap">
    <headNav active="4"></headNav>
    <div class="testUserInfo-content">
      <!-- 社会艺术水平考试 -->
      <div v-if="form.type == 'test'">
        <div class="text-l">准考证号：{{ userInfoObj.admissionNumber }}</div>
        <table
          style="margin: 0 auto"
          border="1"
          width="1000px"
          align="center"
          cellspacing="0"
          cellpadding="0"
          class="detailTable"
        >
          <tr align="center" bgcolor="#eee">
            <td colspan="7">考生个人信息</td>
          </tr>
          <tr align="center">
            <td bgcolor="#fff">姓名</td>
            <td>
              <div>{{ userInfoObj.name }}</div>
            </td>
            <td bgcolor="#fff">性别</td>
            <td>
              <div>{{ userInfoObj.sex == 1 ? "男" : "女" }}</div>
            </td>
            <td bgcolor="#fff">民族</td>
            <td>
              <div>{{ userInfoObj.nation }}</div>
            </td>
            <td colspan="2" rowspan="3">
              <div class="uploader-preview">
                <el-image
                  v-if="userInfoObj.idPhotoUrl"
                  style="width: 100%; height: 100%"
                  :src="$ajax.imgUploadUrl(userInfoObj.idPhotoUrl)"
                  :preview-src-list="[
                    $ajax.imgUploadUrl(userInfoObj.idPhotoUrl),
                  ]"
                  :z-index="9999999"
                >
                </el-image>
              </div>
            </td>
          </tr>
          <tr align="center">
            <td bgcolor="#fff">拼音</td>
            <td>
              <div>{{ userInfoObj.pinyin }}</div>
            </td>
            <td bgcolor="#fff">出生日期</td>
            <td colspan="3">
              <div>{{ userInfoObj.birthDateTime }}</div>
            </td>
          </tr>
          <tr align="center">
            <td bgcolor="#fff">证件类型</td>
            <td>
              <div>
                {{
                  userInfoObj.documentType == 1 ? "身份证" : "护照/台胞证及其他"
                }}
              </div>
            </td>
            <td bgcolor="#fff">证件号码</td>
            <td colspan="3">
              <div>{{ userInfoObj.idNumber }}</div>
            </td>
          </tr>
          <tr align="center">
            <td bgcolor="#fff">是否参加乐理考试</td>
            <td colspan="6">
              <div>{{ userInfoObj.isTakeTheExam == 1 ? "是" : "否" }}</div>
            </td>
          </tr>
          <tr align="center">
            <td bgcolor="#fff">家长姓名</td>
            <td>
              <div>{{ userInfoObj.parentName }}</div>
            </td>
            <td bgcolor="#fff">家长手机号</td>
            <td colspan="2">
              <div>{{ userInfoObj.parentPhone }}</div>
            </td>
            <td bgcolor="#fff">电子邮箱</td>
            <td>
              <div>{{ userInfoObj.parentEmail }}</div>
            </td>
          </tr>
          <tr align="center">
            <td bgcolor="#fff">通讯地址</td>
            <td colspan="7">
              <div>{{ userInfoObj.address }}</div>
            </td>
          </tr>
          <tr align="center">
            <td bgcolor="#fff">报名机构名称</td>
            <td colspan="7">
              <div>{{ userInfoObj.registrationAgencyName }}</div>
            </td>
          </tr>
          <tr align="center">
            <td bgcolor="#fff">教师姓名</td>
            <td>
              <div>{{ userInfoObj.teacherName }}</div>
            </td>
            <td bgcolor="#fff">教师手机号</td>
            <td colspan="2">
              <div>{{ userInfoObj.teacherPhone }}</div>
            </td>
            <td bgcolor="#fff">乐理等级</td>
            <td>
              <div>{{ userInfoObj.certificateLevel }}</div>
            </td>
          </tr>
          <tr align="center" bgcolor="#eee">
            <td colspan="8">报考科目信息</td>
          </tr>
          <tr align="center">
            <td bgcolor="#fff">报考科目</td>
            <td>
              <div>{{ userInfoObj.examSubjectId }}</div>
            </td>
            <td bgcolor="#fff">报考级别</td>
            <td colspan="5">
              <div>{{ userInfoObj.examLevelId }}</div>
            </td>
          </tr>
          <tr align="center">
            <td bgcolor="#fff">报考曲目</td>
            <td colspan="7">
              <div>{{ userInfoObj.songCategory }}</div>
            </td>
          </tr>
          <tr align="center" style="height: 200px">
            <td colspan="4">
              <div class="file-preview">
                <el-image
                  v-if="userInfoObj.idCopyUrl"
                  style="width: 100%; height: 100%"
                  :src="$ajax.imgUploadUrl(userInfoObj.idCopyUrl)"
                  :preview-src-list="[
                    $ajax.imgUploadUrl(userInfoObj.idCopyUrl),
                  ]"
                  :z-index="9999999"
                >
                </el-image>
              </div>
            </td>
            <td colspan="4">
              <div class="file-preview">
                <el-image
                  v-if="userInfoObj.certificateUrl"
                  style="width: 100%; height: 100%"
                  :src="$ajax.imgUploadUrl(userInfoObj.certificateUrl)"
                  :preview-src-list="[
                    $ajax.imgUploadUrl(userInfoObj.certificateUrl),
                  ]"
                  :z-index="9999999"
                >
                </el-image>
              </div>
            </td>
          </tr>
        </table>
      </div>
      <!-- 青少年大赛详情 -->
      <div v-if="form.type == 'teenager'">
        <table
          style="margin: 0 auto"
          border="1"
          width="1000px"
          align="center"
          cellspacing="0"
          cellpadding="0"
          class="detailTable"
        >
          <tr align="center" bgcolor="#eee">
            <td colspan="7">个人信息</td>
          </tr>
          <tr align="center">
            <td bgcolor="#fff">姓名</td>
            <td>
              <div>{{ userInfoObj.name }}</div>
            </td>
            <td bgcolor="#fff">性别</td>
            <td>
              <div>{{ userInfoObj.sex == 1 ? "男" : "女" }}</div>
            </td>
            <td bgcolor="#fff">民族</td>
            <td>
              <div>{{ userInfoObj.nation }}</div>
            </td>
            <td colspan="2" rowspan="2">
              <div class="uploader-preview">
                <el-image
                  v-if="userInfoObj.idPhotoUrl"
                  style="width: 100%; height: 100%"
                  :src="$ajax.imgUploadUrl(userInfoObj.idPhotoUrl)"
                  :preview-src-list="[
                    $ajax.imgUploadUrl(userInfoObj.idPhotoUrl),
                  ]"
                  :z-index="9999999"
                >
                </el-image>
              </div>
            </td>
          </tr>
          <tr align="center">
            <td bgcolor="#fff">证件类型</td>
            <td>
              <div>
                {{
                  userInfoObj.documentType == 1 ? "身份证" : "护照/台胞证及其他"
                }}
              </div>
            </td>
            <td bgcolor="#fff">证件号码</td>
            <td colspan="3">
              <div>{{ userInfoObj.idNumber }}</div>
            </td>
          </tr>
          <tr align="center">
            <td bgcolor="#fff">家长姓名</td>
            <td>
              <div>{{ userInfoObj.parentName }}</div>
            </td>
            <td bgcolor="#fff">家长手机号</td>
            <td colspan="2">
              <div>{{ userInfoObj.parentPhone }}</div>
            </td>
            <td bgcolor="#fff">电子邮箱</td>
            <td>
              <div>{{ userInfoObj.parentEmail }}</div>
            </td>
          </tr>
          <tr align="center">
            <td bgcolor="#fff">报名机构名称</td>
            <td colspan="2">
              <div>{{ userInfoObj.registrationAgencyName }}</div>
            </td>
            <td bgcolor="#fff">指导老师姓名</td>
            <td colspan="4">
              <div>{{ userInfoObj.teacherName }}</div>
            </td>
          </tr>
          <tr align="center" bgcolor="#eee">
            <td colspan="8">报名信息</td>
          </tr>
          <tr align="center">
            <td bgcolor="#fff">组别</td>
            <td colspan="1">
              <div>{{ ageGroup.value }}</div>
            </td>
            <td bgcolor="#fff">报名乐组</td>
            <td colspan="1">
              <div>{{ userInfoObj.mgName }}</div>
            </td>
            <td bgcolor="#fff">报名专业</td>
            <td colspan="2">
              <div>{{ userInfoObj.miName }}</div>
            </td>
          </tr>
          <tr align="center">
            <td bgcolor="#fff">演奏曲目</td>
            <td colspan="7">
              <div>{{ userInfoObj.repertoire }}</div>
            </td>
          </tr>
          <template v-for="(item, index) in userInfoObj.otherTracks">
            <tr align="center" :key="index">
              <td bgcolor="#fff">组别</td>
              <td colspan="1">
                <div>{{ item.ageGroup }}</div>
              </td>
              <td bgcolor="#fff">报名乐组</td>
              <td colspan="1">
                <div>{{ item.musicalInstruments }}</div>
              </td>
              <td bgcolor="#fff">报名专业</td>
              <td colspan="2">
                <div>{{ item.musicGroup }}</div>
              </td>
            </tr>
            <tr align="center" :key="index+'key'">
              <td bgcolor="#fff">演奏曲目</td>
              <td colspan="7">
                <div>{{ item.repertoire }}</div>
              </td>
            </tr>
          </template>
          <tr align="center" style="height: 200px">
            <td colspan="8">
              <div class="file-preview">
                <el-image
                  v-if="userInfoObj.idCopyUrl"
                  style="width: 100%; height: 100%"
                  :src="$ajax.imgUploadUrl(userInfoObj.idCopyUrl)"
                  :preview-src-list="[
                    $ajax.imgUploadUrl(userInfoObj.idCopyUrl),
                  ]"
                  :z-index="9999999"
                >
                </el-image>
              </div>
            </td>
          </tr>
        </table>
      </div>
      <a
        :href="$ajax.imgUploadUrl(userInfoObj.ossUrl)"
        target="_blank"
        download
      >
        <div class="btn">下载准考证</div>
      </a>
    </div>
    <footNav />
  </div>
</template>
<script>
import headNav from "../components/header2.vue";
import footNav from "../components/footer2.vue";
export default {
  components: { headNav, footNav },
  data() {
    return {
      form: {
        name: "",
        idNumber: "",
        type: "",
      },
      userInfoObj: {},
      ageGroup: {},
    };
  },
  mounted() {
    this.form = this.$route.query;
    if (this.form.type == "test") {
      this.getExamRegistrationDetail();
    } else {
      this.getCompetitionDetail();
    }
  },
  methods: {
    // 社会水平艺术考试
    getExamRegistrationDetail() {
      this.$ajax
        .get(
          this.$ajax.localOrOnline() + "examRegistration/registration/info",
          this.form
        )
        .then((res) => {
          if (res.code == 200) {
            if (res.data) {
              let data = res.data;
              let admissionTicket = data.admissionTicket;
              let er = data.er;
              Object.assign(this.userInfoObj, admissionTicket, er);
              this.userInfoObj.examLevelId = data.el.level;
              this.userInfoObj.examSubjectId = data.es.subject;
              this.userInfoObj.songCategory =
                data.elsc.songCategory + "，" + data.elscTwo.songCategory;
              this.userInfoObj.certificateLevel = data.cl.value;
              this.$forceUpdate();
            } else {
              this.$alert("没有获取到您的报名信息", {
                confirmButtonText: "确定",
              });
            }
          } else {
            this.$message(res.message);
          }
        });
    },
    // 青少年大赛
    getCompetitionDetail() {
      this.$ajax
        .get(
          this.$ajax.localOrOnline() + "instrument/competition/info",
          this.form
        )
        .then((res) => {
          if (res.code == 200) {
            if (res.data) {
              let data = res.data;
              this.userInfoObj = data.ic;
              this.userInfoObj.mgName = data.mg.name;
              this.userInfoObj.miName = data.mi.name;
              this.ageGroup = data.ageGroup;
              this.userInfoObj.otherTracks = data.otherTracks || [];
              this.$forceUpdate();
            } else {
              this.$alert("没有获取到您的报名信息", {
                confirmButtonText: "确定",
              });
            }
          } else {
            this.$message(res.message);
          }
        });
    },
  },
};
</script>
<style scoped lang="scss">
.testUserInfo-wrap {
  .testUserInfo-content {
    width: 1000px;
    margin: 50px auto;
    tr {
      height: 70px;
    }
    td {
      width: 100px;
      padding: 0 5px;
    }
    .uploader-preview {
      width: 71px;
      height: 99px;
    }

    .file-preview {
      width: 20rem;
      height: 15rem;
    }
    .btn {
      width: 300px;
      text-align: center;
      line-height: 50px;
      color: #fff;
      background: #bf783d;
      border-radius: 4px;
      margin: 70px auto 0;
      cursor: pointer;
    }
  }
}
</style>