<template>
  <!-- 巡演头部切换 -->
  <div class="showHeader-wrap">
    <div class="w1200 flex between">
      <div
        class="item"
        :class="showHeaderIndex == index ? 'item-acitve' : ''"
        v-for="(item, index) in list"
        :key="index"
        @click="change(index)"
      >
        <div
          class="top-img"
          :style="{
            backgroundImage: 'url(' + item.src + ')',
          }"
        ></div>
        <div class="bot" :style="{padding: $i18n.locale == 'zh' ? '0 28px' : '0 18px'}">
          <div
            class="bot-text"
            :class="showHeaderIndex == index ? 'bot-text-active' : ''"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    showHeaderIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      currentIndex: 0,
      list: [
        {
          name: this.$t("showHeader.oz"),
          src: require("../assets/version2/home-6.png"),
        },
        {
          name: this.$t("showHeader.hg"),
          src: require("../assets/version2/home-8.png"),
        },
        {
          name: this.$t("showHeader.rb"),
          src: require("../assets/version2/home-7.png"),
        },
        {
          name: this.$t("showHeader.mg"),
          src: require("../assets/version2/home-5.png"),
        },
        {
          name: this.$t("showHeader.oz2"),
          src: require("../assets/version2/home-9.png"),
        },
      ],
    };
  },
  methods: {
    change(index) {
      this.$emit("showHeaderChange", index);
    },
  },
};
</script>
<style scoped lang="scss">
.showHeader-wrap {
  background-color: #f2f6f7;
  padding: 56px 20px 72px;
  .item {
    width: 163px;
    height: 179px;
    border-radius: 30px 0 30px 0;
    overflow: hidden;
    background: transparent;
    cursor: pointer;
    &-acitve {
      background: #835c46;
    }
    .top-img {
      width: 163px;
      height: 94px;
      background-size: 100% 100%;
      background-color: #000;
    }
    .bot {
      border: 1px solid #835c46;
      border-radius: 0 0 30px 0;
      width: 163px;
      height: 85px;
      line-height: 80px;
      text-align: center;
      padding: 0 28px;
      .bot-text {
        display: inline-block;
        font-size: 24px;
        font-weight: 300;
        color: #666666;
        line-height: 25px;
        vertical-align: middle;
        &-active {
          font-weight: 400;
          color: #ffffff;
        }
      }
    }
  }
}
</style>