<template>
  <div class="business-wrap">
    <headNav active="6"></headNav>
    <div class="business-contents">
      <p class="fs20 tal tips">
        {{$t('business.title')}}
      </p>
      <div class="about tal">
        <h2 class="pb40">{{$t('business.contact')}}</h2>
        <p class="flex v-center pb30 cmain fs16">
          <img class="mr12" src="../assets/address-icon2.png" />
          {{$t('business.location')}}
        </p>
        <p class="flex v-center pb30 cmain fs16">
          <img class="mr12" src="../assets/mail-icon2.png" />suzhousco@foxmail.com
        </p>
        <p class="flex v-center pb30 cmain fs16">
          <img class="mr12" src="../assets/phone-icon2.png" /> 0512-69589013
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import headNav from "../components/header2.vue";
export default {
  components: { headNav },
};
</script>

<style scoped lang="scss">
.business-wrap {
  width: 100%;
  min-height: 1200px;
  background: url("../assets/business-bg.jpg") no-repeat 50% 0;
  background-size: 100% 100%;
  position: relative;
  overflow: hidden;
}
.business-contents {
  width: 585px;
  background: rgba(255, 255, 255, 0.85);
  padding: 100px 100px 0;
  float: right;
  margin:10% 15% 5% 0;
  .tips {
    line-height: 180%;
  }

  .about{
    margin-top: 100px;
  }
}
</style>
